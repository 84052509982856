import React from 'react'
import Footer from '../component/footer/Footer'
import NewHeader from '../component/header/NewHeader'
import NewsHeaderImg from '../assets/img/news/jpg/news_banner.jpg'
import Bg1 from '../assets/images/bg/abs-bg4.png'
import Posts from './NewsPostData'
import  '../component/styles/styles.js'

export default function News() {

  return (
    <>
    <NewHeader />
    {/* Header */}
    <section className="page-title-section" style={{
                                    backgroundImage: "url(" + NewsHeaderImg + ")",
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                }} >
        <div className="container">
            <div className="row">
                <div className="col-xl-12 text-center">
                    <div className="page-title-content">
                        <h1 className="title text-white">Healthcare News</h1>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="{{route('index')}}">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">News</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* Header */}
    {/* Posts */}
    <section className="bg-silver-light pdt-105 pdb-80" style={{backgroundImage: "url(" + Bg1 + ")",}}>
        <h3 className="text-center" style={{marginBottom: '40px'}}>Take a sneak peek into our recent healthcare analysis</h3>
        <div className="section-content">
            <div className="container">
                <div className="row">
                    {
                        Posts.map((item , index)=>{
                            return (
                                <div className="col-md-6 col-lg-6 col-xl-4" key={index}>
                                    <div className="news-wrapper mrb-30 mrb-sm-40">
                                        <div className="news-thumb">
                                            <img className="img-full" src={item.image} alt="" />
                                            <div className="news-top-meta">
                                                <span className="entry-category">Press Release</span>
                                            </div>
                                        </div>
                                        <div className="news-details">
                                            <div className="news-description mb-20">
                                                <h4 className="the-title mrb-30"><a href={item.url} target="_blank">{item.title}</a></h4>
                                                <div className="news-bottom-meta">
                                                    <span className="entry-date mrr-20"><i className="far fa-calendar-alt mrr-10 text-primary-color"></i>{item.date}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>    
            </div>
        </div>            
    </section>
    {/* Posts */}
    <Footer />
    </>
  )
}
