import React, { useState, setState } from 'react'
import Footer from '../component/footer/Footer'
import NewHeader from '../component/header/NewHeader'
import ContactHeaderImg from '../assets/img/contact-us/jpg/contact-us-banner.jpg'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import axios from 'axios';
import validate from './Validation'
import config from '../../Config'
import { encode_str, decode_str } from '../../helper'


export default function Contact() {



    

    const [inputs, setInputs] = useState({});
    const [errors, setErrors] = useState({})
    const [loader, setLoader] = useState(false)

    const handleChange = (event) => {
        setErrors({});
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }

    // validate();

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoader(true);
        if (validate(inputs, setErrors, setLoader)) {
           var count=((window.location.href).split('/')).length
            var arr=(window.location.href).split('/')
            axios.post(config.api_url+"contact",{data: encode_str(JSON.stringify({
                name: inputs.name,
                email: inputs.email,
                phone: inputs.phone,
                company: inputs.company,
                message: inputs.message,
                page_name: arr[count-1]
            }))}).then(function (response) {
                // handle success
                console.log(response)
                if (response.status == 200) {
                    setInputs('')
                    setErrors('')
                    setLoader(false)
                    return Swal.fire({
                        title: 'success!',
                        text: 'Thank you for showing your interest. We have received your request and our professionals will assist you shortly.',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    })
                } else {
                };
            }).catch(function (error) {
                setLoader(false)
                return Swal.fire({
                    title: 'error!',
                    text: `${error.response.data.reason.phone}`,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            });
        }
    }

    return (
        <>
            <NewHeader />
            {/* Header */}
            <section className="page-title-section"
                style={{
                    backgroundImage: "url(" + ContactHeaderImg + ")",
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 text-center">
                            <div className="page-title-content">
                                <h1 className="title text-white">Contact Us</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Header */}
            {/* Page Data */}
            <section className="contact-section pdt-110 pdb-95 pdb-lg-90" data-background="images/bg/abs-bg1.png">
                <div className="container">
                    <div className="row mrb-40">
                        <div className="col-lg-6 col-xl-4">
                            <div className="contact-block d-flex mrb-30">
                                <div className="contact-icon">
                                    <i className="webex-icon-map1"></i>
                                </div>
                                <div className="contact-details mrl-30">
                                    <h4 className="icon-box-title mrb-10" style={{ fontSize: '18px', fontWeight: '600' }}>Our Address</h4>
                                    <p className="mrb-0" style={{ color: 'black' }}>304 S. Jones Blvd #2432, Las Vegas NV 89107</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-4">
                            <div className="contact-block d-flex mrb-30">
                                <div className="contact-icon">
                                    <i className="webex-icon-Phone2"></i>
                                </div>
                                <div className="contact-details mrl-30">
                                    <h5 className="icon-box-title mrb-10">Phone Number</h5>
                                    <a href="tel: +19193216187"><p className="mrb-0" style={{ color: 'black' }}>+1(919)321-6187</p></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-4">
                            <div className="contact-block d-flex mrb-30">
                                <div className="contact-icon">
                                    <i className="webex-icon-envelope"></i>
                                </div>
                                <div className="contact-details mrl-30">
                                    <h6 className="icon-box-title mrb-10" style={{ fontSize: '18px' }}>Email Us</h6>
                                    <p className="mrb-0"><a href="mailto:info@delveinsight.co.uk" style={{ color: 'black' }}>info@delveinsight.co.uk</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 col-xl-5">
                            <h3 className="sub-title-side-line text-primary-color mrt-0 mrb-15" style={{ fontSize: '18px' }}>Get In Touch</h3>
                            <h2 className="faq-title mrb-30">Have Any Questions?</h2>

                            <p className="mrb-40">Reach out to us in case of any query or discrepancy through mail info@delveinsight. You can also contact us through our various social media handles.</p>

                            <ul className="social-list list-lg list-primary-color list-flat mrb-md-60 clearfix">
                                <li><a href="https://www.facebook.com/delveinsightindia/"><i className="fab fa-facebook"></i></a></li>
                                <li><a href="https://twitter.com/delve_insight"><i className="fab fa-twitter"></i></a></li>
                                <li><a href="https://in.linkedin.com/company/delveinsight-business-research-llp"><i className="fab fa-linkedin"></i></a></li>
                            </ul>
                        </div>
                        <div className="col-lg-7 col-xl-7">
                            <div className="contact-form">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group mrb-25">
                                                <input type="text" name="name" value={inputs.name || ""} onChange={handleChange} placeholder="Name" className="form-control" />
                                                <span className='text-danger'>{errors.nameError}</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group mrb-25">
                                                <input type="text" value={inputs.phone || ""} onChange={handleChange} placeholder="Phone" name="phone" className="form-control" />
                                                <span className='text-danger'>{errors.phoneError}</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group mrb-25">
                                                <input type="email" value={inputs.email || ""} onChange={handleChange} placeholder="Email" name="email" className="form-control" />
                                                <span className='text-danger'>{errors.emailError}</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group mrb-25">
                                                <input type="text" value={inputs.company || ""} onChange={handleChange} placeholder="Company" name="company" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group mrb-25">
                                                <textarea rows="4" value={inputs.message || ""} onChange={handleChange} placeholder="Messages" name="message"
                                                    className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="col-lg-8">
                                            <div className="form-group">
                                                <button type="submit" style={{ display: 'flex',  justifyContent: 'space-between',alignItems: 'center'}} className="cs-btn-one btn-md btn-round btn-primary-color element-shadow">
                                                {loader == true ? 
                                                <div className="spinner-border" style={{ padding: '10px', marginRight: '20px'}} role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div> : ''}
                                                Submit Now</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="contact-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 p-0">
                            <div className="mapouter fixed-height">
                                <div className="gmap_canvas">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3220.8567768018015!2d-115.22579408537956!3d36.170039710617345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c8c10888efffff%3A0xefd1da6d8d57f5ed!2s304%20S%20Jones%20Blvd%20%232432%2C%20Las%20Vegas%2C%20NV%2089107%2C%20USA!5e0!3m2!1sen!2sin!4v1632312550950!5m2!1sen!2sin"></iframe>
                                    <a href="https://www.whatismyip-address.com"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Page Data */}
            <Footer />
        </>
    )
}
