const Posts = [
    {
        date  : '22 Sept, 2021',
        image : require("../assets/img/press-release/jpg/Human-Papillomavirus-Cancer-Market.jpg"),
        title : 'HPV 16+ Cancer Market' ,
        url   : 'https://www.prnewswire.co.uk/news-releases/hpv-16-cancer-market-size-growth-to-shore-up-at-a-substantial-cagr-of-21-6-during-the-study-period-2018-30-in-the-7mm-delveinsight-896464683.html'
    },
    {
        date  : '22 Sept, 2021',
        image : require("../assets/img/press-release/jpg/Scleroderma-Pipeline.jpg"),
        title : 'Scleroderma Pipeline' ,
        url   : 'https://www.globenewswire.com/news-release/2021/09/23/2301823/0/en/Scleroderma-Pipeline-Experiences-Momentum-DelveInsight-Estimates-a-Diverse-Pipeline-Comprising-a-Broad-Spectrum-of-Molecular-Targets.html'
    },
    {
        date  : '21 Sept, 2021',
        image : require("../assets/img/press-release/jpg/Blood-Purification-Devices-Market.jpg"),
        title : 'Blood Purification Devices' ,
        url   : 'https://www.globenewswire.com/news-release/2021/09/22/2300998/0/en/The-Blood-Purification-Devices-market-is-expected-to-report-a-total-revenue-of-USD-5-33-billion-by-2026-Foresees-DelveInsight.html'
    },
    {
        date  : '21 Sept, 2021',
        image : require("../assets/img/press-release/jpg/Cardiopulmonary-Bypass-Equipment-Market.jpg"),
        title : 'The Cardiopulmonary Bypass Equipment' ,
        url   : 'https://www.prnewswire.co.uk/news-releases/the-cardiopulmonary-bypass-equipment-market-is-expected-to-grow-at-a-cagr-of-4-13-and-will-reach-usd-389-14-million-by-2026-according-to-delveinsight-816620206.html'
    },
    {
        date  : '20 Sept, 2021',
        image : require("../assets/img/press-release/jpg/Advanced-Ovarian-Cancer-Pipeline-Report.jpg"),
        title : 'Advanced Ovarian Cancer Pipeline Report' ,
        url   : 'https://www.globenewswire.com/news-release/2021/09/21/2300170/0/en/Advanced-Ovarian-Cancer-Pipeline-Report-Current-Therapies-Emerging-Drugs-and-Treatment-Outlook-DelveInsight.html'
    },
    {
        date  : '16 Sept, 2021',
        image : require("../assets/img/press-release/jpg/Migraine-Pipeline.jpg"),
        title : 'Migraine Pipeline Space Brims with...' ,
        url   : 'https://www.globenewswire.com/news-release/2021/09/17/2298821/0/en/Migraine-Pipeline-Space-Brims-with-Novel-Emerging-Therapies-with-Over-30-Pharma-Players-Working-in-the-Domain-DelveInsight.html'
    },
    {
        date  : '15 Sept, 2021',
        image : require("../assets/img/press-release/jpg/Adult-Myopia-Market.jpg"),
        title : 'Adult Myopia Market to Project High...' ,
        url   : 'https://www.prnewswire.co.uk/news-releases/adult-myopia-market-to-project-high-at-a-10-46-cagr-during-the-study-period-2018-2030-states-delveinsight-875075878.html'
    },
    {
        date  : '15 Sept, 2021',
        image : require("../assets/img/press-release/jpg/Pompe-Disease-Pipeline.jpg"),
        title : 'Pompe Disease Pipeline Landscape' ,
        url   : 'https://www.globenewswire.com/news-release/2021/09/16/2298038/0/en/DelveInsight-Estimates-a-Promising-Picture-of-Pompe-Disease-Pipeline-Landscape-in-the-Coming-Years-as-Key-Pharma-Companies-Bolster-Rare-Disease-Pipeline.html'
    },
    {
        date  : '09 Sept, 2021',
        image : require("../assets/img/press-release/jpg/Chemotherapy-Induced-Diarrhea-Pipeline.jpg"),
        title : 'Chemotherapy-Induced Diarrhea' ,
        url   : 'https://www.globenewswire.com/en/news-release/2021/09/10/2294869/0/en/Chemotherapy-Induced-Diarrhea-Pipeline-Landscape-Analysis-DelveInsight.html'
    },
    {
        date  : '08 Sept, 2021',
        image : require("../assets/img/press-release/jpg/Choroideremia-Pipeline.jpg"),
        title : 'Drug Development of Choroideremia Pipeline' ,
        url   : 'https://www.globenewswire.com/news-release/2021/09/09/2293890/0/en/Drug-Development-of-Choroideremia-Pipeline-Report-Analysis-DelveInsight.html'
    },
    {
        date  : '07 Sept, 2021',
        image : require("../assets/img/press-release/jpg/Diabetic-Nephropathy-Market.jpg"),
        title : 'Diabetic Nephropathy Market' ,
        url   : 'https://www.prnewswire.co.uk/news-releases/diabetic-nephropathy-market-to-gain-from-increasing-patient-burden-and-new-drug-launches-growing-at-a-6-5-cagr-during-the-study-period-2018-2030-evaluates-delveinsight-820310134.html'
    },{
        date  : '07 Sept, 2021',
        image : require("../assets/img/press-release/jpg/Human-Papillomavirus-Cancers-Pipeline.jpg"),
        title : 'HPV 16+ Cancer Pipeline Analysis' ,
        url   : 'https://www.globenewswire.com/news-release/2021/09/08/2292999/0/en/HPV-16-Cancer-Pipeline-Analysis-Insights-into-Emerging-Therapies-Key-Companies-Clinical-Trials-and-Future-Pipeline-Outlook-DelveInsight.html'
    },{
        date  : '06 Sept, 2021',
        image : require("../assets/img/press-release/jpg/Fecal-Incontinence-Market.jpg"),
        title : 'Fecal Incontinence Therapeutics Market' ,
        url   : 'https://www.prnewswire.com/news-releases/fecal-incontinence-therapeutics-market-size-expected-to-surge-with-a-cagr-of-3-96-in-the-7mm-during-the-study-period-2018-30--delveinsight-301369748.html'
    },{
        date  : '06 Sept, 2021',
        image : require("../assets/img/press-release/jpg/Periodontal-Disease-Pipeline.jpg"),
        title : 'Periodontal Disease Pipeline' ,
        url   : 'https://www.globenewswire.com/news-release/2021/09/07/2292099/0/en/Periodontal-Disease-Pipeline-Review-Clinical-Trials-Analysis-Report-2021-DelveInsight.html'
    },{
        date  : '02 Sept, 2021',
        image : require("../assets/img/press-release/jpg/Charcot-Marie-Tooth-Disease-Market.jpg"),
        title : 'Charcot Marie Tooth Disease' ,
        url   : 'https://www.prnewswire.com/news-releases/charcot-marie-tooth-disease-therapeutic-market-size-expected-to-expand-at-a-cagr-of-25-42-for-the-study-period-20182030--delveinsight-301368313.html'
    },{
        date  : '01 Sept, 2021',
        image : require("../assets/img/press-release/jpg/Hemophilia-B-Market.jpg"),
        title : 'Hemophilia B Market' ,
        url   : 'https://www.prnewswire.com/news-releases/hemophilia-b-market-to-climb-swiftly-at-an-8-9-cagr-during-the-study-period-20182030-evaluates-delveinsight-301366948.html'
    },{
        date  : '31 Aug, 2021',
        image : require("../assets/img/press-release/jpg/Gout-Pipeline-Insights.jpg"),
        title : 'Gout Pipeline Insights' ,
        url   : 'https://www.globenewswire.com/news-release/2021/09/01/2289609/0/en/DelveInsight-Estimates-a-Promising-Gout-Pipeline-Owing-to-an-Expected-Entry-of-Novel-Therapeutic-Agents.html'
    },{
        date  : '31 Aug, 2021',
        image : require("../assets/img/press-release/jpg/Von-Hippel-Lindau-(VHL)-Disease-Market.jpg"),
        title : 'Von Hippel-Lindau Disease' ,
        url   : 'https://www.prnewswire.co.uk/news-releases/von-hippel-lindau-disease-therapeutic-market-size-expected-to-surge-at-a-cagr-of-8-3-for-the-study-period-2018-2030-delveinsight-848708710.html'
    },
    {
        date  : '26 Aug, 2021',
        image : require("../assets/img/press-release/jpg/Functional-Dyspepsia-Market.jpg"),
        title : 'Functional Dyspepsia' ,
        url   : 'https://www.prnewswire.com/news-releases/functional-dyspepsia-market-in-7mm-is-estimated-to-show-modest-growth-at-0-58-cagr-during-the-study-period-20182030-evaluates-delveinsight-301363655.html'
    },
    {
        date  : '31 Aug, 2021',
        image : require("../assets/img/press-release/jpg/Non-Hodgkin's-Lymphoma-(NHL)-Pipeline.jpg"),
        title : "Non-Hodgkin's Lymphoma" ,
        url   : 'https://www.globenewswire.com/news-release/2021/08/27/2287486/0/en/Non-Hodgkin-s-Lymphoma-Pipeline-Experiences-Momentum-DelveInsight-Estimates-a-Diverse-Pipeline.html'
    },
  ]

  export default Posts