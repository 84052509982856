
var base_url=window.location.origin;
if(base_url==='http://localhost:3000'){
    const config={
        'api_url':'http://localhost:8080/',
        'name':'Dishant',
        'base_url':base_url
    }
    module.exports=config;
}else{
    const config={
        'api_url':'https://api.delveinsight.us:8443/',
        'name':'Dishant',
        'base_url':base_url
    }
    module.exports=config;
}
