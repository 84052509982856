import React , {useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import axios from 'axios';
import validate from '../../pages/Validation'
// import { useHistory } from "react-router-dom";


import image from '../../assets/img/services/jpg/consulting-services-banner.jpg'
import NotFound from '../../pages/NotFound';

export default function ServiceComponent(data) {
    const [model, setModel] = useState('')

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const handleShow = () => setShow(true);

    const [inputs, setInputs] = useState({});

    const [errors, setErrors] = useState({})

    const [loader, setLoader] = useState(false)

    const handleChange = (event) => {
        setErrors({});
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }

    // validate();

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoader(true);
        if (validate(inputs, setErrors, setLoader)) {
           var count=((window.location.href).split('/')).length
            var arr=(window.location.href).split('/')
            axios.post("http://localhost:3001/contact", {
                name: inputs.name,
                email: inputs.email,
                phone: inputs.phone,
                company: inputs.company,
                message: inputs.message,
                page_name: arr[count-1]
            }).then(function (response) {
                if (response.status === 200) {
                    setInputs('')
                    setErrors('')
                    setLoader(false)
                    handleClose()
                    return Swal.fire({
                        title: 'success!',
                        text: 'Thank you for showing your interest. We have received your request and our professionals will assist you shortly.',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    })
                } else {
                };
            }).catch(function (error) {
                setLoader(false)
                return Swal.fire({
                    title: 'error!',
                    text: `${error.response.data.reason.phone}`,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            });
        }
    }

    var list_date = data.all_data.filter((element) => element.slug === data.path);

    const navigate = useNavigate();
   

    if (list_date.length === 0) {
        navigate('/notfound');
        return (<NotFound />)

    } else {
        return (
        <>
            {/* Model */}
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title style={{textAlign: 'center'}}>{model}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group form-group-new">
                                        <input type="text" name="name" value={inputs.name || ""} onChange={handleChange} placeholder="Name" className="form-control form-control-new" />
                                        <span className='text-danger'>{errors.nameError}</span>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group mrb-25">
                                        <input type="text" value={inputs.phone || ""} onChange={handleChange} placeholder="Phone" name="phone" className="form-control" />
                                        <span className='text-danger'>{errors.phoneError}</span>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group form-group-new">
                                        <input type="email" name="email" value={inputs.email || ""} onChange={handleChange} placeholder="Email" className="form-control form-control-new" />
                                        <span className='text-danger'>{errors.emailError}</span>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group form-group-new">
                                        <input type="text" name="company" value={inputs.company || ""} onChange={handleChange} placeholder="Company Name" className="form-control form-control-new" />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group form-group-new">
                                        <input type="textarea" name="message" value={inputs.message || ""} onChange={handleChange} placeholder="Message" className="form-control form-control-new" style={{height: '100px'}}  />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group mrb-0 text-center">
                                        <button type="submit" className="cs-btn-one btn-primary-color btn-sm">{loader === true ? 
                                        <div className="spinner-border" style={{ padding: '10px', marginRight: '20px'}} role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div> : ''} Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        </div>

                    </Modal.Body>
                </Modal>
                {/* Model */}
        <div>
             <section className="page-title-section" style={{
                 backgroundImage: "url(" + image + ")",
                 backgroundPosition: 'center',
                 backgroundSize: 'cover',
                 backgroundRepeat: 'no-repeat',
             }}>
                 <div className="container">
                     <div className="row">
                         <div className="col-xl-12 text-center">
                             <div className="page-title-content">
                                 <h3 className="title text-white">{list_date[0].title}</h3>
                                 <nav aria-label="breadcrumb">
                                     <ol className="breadcrumb">
                                         <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                         <li className="breadcrumb-item"><Link>Services</Link></li>
                                         <li className="breadcrumb-item active" aria-current="page">{list_date[0].title}</li>
                                     </ol>
                                 </nav>
                             </div>
                         </div>
                     </div>
                 </div>
             </section>
             <section className="service-details-page pdt-110 pdb-90">
                 <div className="container">
                     <div className="row">
                         <div className="col-xl-8 col-lg-7">
                             <div className="service-detail-text">
                                 <h2 className="mrb-20" style={{ fontSize: '28px' }}>Service Details</h2>
                                 <p className="mrb-40">{list_date[0].para1}</p>
                                 <div className="blog-standared-img slider-blog mrb-35">
                                     <img className="img-full" src={list_date[0].image} alt="" />
                                 </div>
                                 <p className="mrb-40">{list_date[0].para2}</p>
                                 <div className="service-details-content">
 
                                     <div className="row d-flex mrb-40">
                                         <div className="col-lg-12 col-xl-12">
                                             <h3 className="mrb-20">Key Takeaways</h3>
                                             <ul className="order-list primary-color mrb-lg-40">
                                                 {list_date[0].keywords.map((item) => {
                                                     return <li key={item}>{item}</li>
                                                 })}
 
                                             </ul>
                                         </div>
 
                                     </div>
 
                                 </div>
                             </div>
                         </div>
                         <div className="col-xl-4 col-lg-5 sidebar-right">
                             <div className="service-nav-menu mrb-30">
                                 <div className="service-link-list mb-30">
                                     <ul className="">
                                         {data.all_data.map((item, index) => {
                                             if (item.slug === list_date[0].slug) {
 
                                                 return (<li className="active" key={index}><Link to={'/service/' + item.slug}><i className="fa fa-chevron-right"></i>{item.title}</Link></li>)
                                             } else {
                                                 return <li key={index}><Link to={'/service/' + item.slug}><i className="fa fa-chevron-right"></i>{item.title}</Link></li>
                                             }
 
                                         })}
                                     </ul>
                                 </div>
                             </div>
 
                             <div className="sidebar-widget bg-gradient-color text-center" data-background="images/bg/abs-bg4.html">
                                 <div className="contact-information">
                                     <h3 className="text-white mrb-20">Contact Us</h3>
                                     <p className="text-white">If you have any query about our service please contact with us</p>
                                     <div className="testimonial-thumb">
                                         <img src={require('../../assets/img/services/jpg/Ribhu.jpg')} alt="Delveinsight Executive" />
                                     </div>
                                     <ul className="list-items text-white mrb-20">
                                         <li><span className="fas fa-phone alt mrr-10 text-white"></span><a href="tel: +19193216187" style={{ color: 'white' }}>+1(919)321-6187</a></li>
 
                                         <li><span className="fas fa-envelope mrr-10 text-white"></span><a href="mailto:info@delveinsight.co.uk" style={{ color: 'white' }}>info@delveinsight.co.uk</a></li>
                                     </ul>
                                     <Link className="cs-btn-one btn-light mrt-15 open-popup" onClick={ () => {handleShow() ; setModel(list_date[0].title)}}>Request For Proposal </Link>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
             </section>
         </div>
        </>
)
    }

}

