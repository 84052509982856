import React from 'react'
import Footer from '../component/footer/Footer'
import NewHeader from '../component/header/NewHeader'
import ServiceComponent from '../component/services/ServiceComponent'



export default function Services() {

  const Path = (window.location.href).split('/service/');

  const data_list = [
    {
      'title': 'Market Forecast Analysis',
      'image': require('../assets/img/services/jpg/Market-Forecast-Analysis.jpg'),
      'para1': 'We provide the latest market analysis as well as forecasts along with the industry trends for a specific disease. Our multidimensional, purposeful, and credible market analysis will assist you in accelerating your business growth and overcoming challenges practically.',
      'para2': 'Our keen insights of the market forecast keep the clients up to date on recent developments in the healthcare industry and provide an edge in the competitive pharmaceutical market. We have a vast repository of over 1500 market reports spanning more than 27 different therapeutic areas across 15+ geographies. Our indication forecast models includes epidemiological based approach which helps clients in understanding the granular view of the market and to understand how market is going to evolve in the next decade.',
      'slug': 'market-forecast-analysis',
      'keywords': [
        '10 years forecast upto 2030',
        'Epidemiological Analysis & Forecasting',
        'Key Cross Competition',
        'Emerging Drug Analysis & Drug Update',
        '7MM Analysis + Customisable Geographies',
        'Market Drivers and Barriers',
      ]
    },
    {
      'title': 'Indication Pipeline Analysis',
      'image': require('../assets/img/services/jpg/Indication-Pipeline-Analysis.jpg'),
      'para1': 'We offer a broad spectrum of indication pipeline reports analysis for any indication covering the trials from preclinical to marketed, competitive assessment along with clinical trial analysis.We also assess the products’ therapeutics by development stage, product type, route of administration, molecule type, and MOA type for a specific indication through our indication pipeline analysis. We have more than 1200 pipeline reports in our databases.',
      'para2': ' ',
      'slug': 'indication-pipeline-analysis',
      'keywords': [
        'Therapeutic assessment',
        'Discontinued pipeline project',
        'R&D and trials details',
        'Collaboration and Acquisition deals',

      ]
    },
    {
      'title': 'Competitive Intelligence',
      'image': require('../assets/img/services/jpg/Competitive-Intelligence.jpg'),
      'para1': 'With research-based solutions, our competitive intelligence consulting services help you obtain a competitive advantage. Our strategy considers the viewpoints of customers, suppliers, business competitors, and others. We collect market data from all corners of the industry and turn it into meaningful information.',
      'para2': 'Our Competitive Intelligence force, which consists of analysts, senior analysts, managers, KOLs provides our partners with the most up-to-date and accurate intelligence available. We have a specialty in Primary Competitive Intelligence.',
      'slug': 'competitive-intelligence',
      'keywords': [
        'Opinion Analysis',
        'Congress Intelligence',
        'Strategy Assessment',
        'News Assessment',

      ]
    }
    ,
    {
      'title': 'Indication Epidemiological Analysis',
      'image': require('../assets/img/services/jpg/Indication-Epidemiological-Analysis.jpg'),
      'para1': 'We offer an in-depth analysis of the historical as well as the epidemiological trends specific for an indication. The indication epidemiology analysis covers a detailed overview of the particular disease, its causes, classification, pathophysiology, diagnosis, and treatment patterns.',
      'para2': 'Our indication epidemiology analysis will help you track the growth opportunities in the various geographies for a specific indication. We have approximately 1200 epidemiological reports of 27 different therapeutic areas across 15+ geographies in our library.',
      'slug': 'indication-epidemiological-analysis',
      'keywords': [
        '10 Years Forecast',
        'Disease Risk and burden',
        'Risk of disease by segmentation',
        'Patient Segmentation',
        'Epidemiological Forecasts upto 2030',
      ]
    },
    {
      'title': 'Rare Disease Analysis',
      'image': require('../assets/img/services/jpg/Rare-Disease.jpg'),
      'para1': 'DelveInsight’s Rare Disease portfolio contains 500+ rare diseases reports. These reports provide analysis of various epidemiological trends, impact and analysis of marketed and upcoming therapies, and competitive assessment of key companies involved in the disease market. Rare diseases analysis proffers a detailed overview of rare diseases, their causes, classification, pathophysiology, diagnosis, and currently available therapies.',
      'para2': 'We also provide the assessment of the novel therapies and their impact on the current treatment landscape. The therapies under development are focused on novel approaches to treat/improve the rare disease condition.',
      'slug': 'rare-disease-analysis',
      'keywords': [
        '10 Year Forecast',
        'Key Competitors',
        'Key Companies Involved',
        'Pipeline Product Profiles',

      ]
    },
    {
      'title': 'Medical Device Competitive Insights',
      'image': require('../assets/img/services/jpg/Medical-Device-Competitive-Insights.jpg'),
      'para1': "Medical Device Competitive Insights examines the medical device market's growth, trends, and analysis, as well as the market's dynamics. Medical devices are used in diagnostics, imaging, wearable devices, remote patient monitoring devices, and point-of-care testing.",
      'para2': 'We provide a complete analysis of the competitors and the marketed devices along with the upcoming devices. Medical Device Competitive Insights provides an edge while developing business strategies by understanding trends shaping and driving the global medical devices market.',
      'slug': 'medical-device-competitive-insights',
      'keywords': [
        '06 Years Forecast',
        'Competitive Landscape',
        'Key products and Key players',
        'Market Drivers and Barriers',
      ]
    }


  ]


  return (
    <div>
      <NewHeader />
      <ServiceComponent all_data={data_list} path={Path[1]} />
      <Footer />
    </div>
  )
}
