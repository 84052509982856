import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import React , {useEffect} from 'react'




const DataTable = (props) => {
    useEffect(() => {
    if (!$.fn.DataTable.isDataTable("#myTable")) {
                $(document).ready(function () {
                setTimeout(function () {
                    $("#table").DataTable({
                    pagingType: "full_numbers",
                    pageLength: 10,
                    processing: true,
                    dom: "Bfrtip",
                    select: {
                        style: "single",
                    },
        
                    buttons: [
                        {
                        extend: "pageLength",
                        className: "btn btn-secondary bg-secondary",
                        },
                        {
                        extend: "copy",
                        className: "btn btn-secondary bg-secondary",
                        },
                        {
                        extend: "csv",
                        className: "btn btn-secondary bg-secondary",
                        },
                        {
                        extend: "print",
                        customize: function (win) {
                            $(win.document.body).css("font-size", "10pt");
                            $(win.document.body)
                            .find("table")
                            .addClass("compact")
                            .css("font-size", "inherit");
                        },
                        className: "btn btn-secondary bg-secondary",
                        },
                    ],
        
                    fnRowCallback: function (
                        nRow,
                        aData,
                        iDisplayIndex,
                        iDisplayIndexFull
                    ) {
                        var index = iDisplayIndexFull + 1;
                        $("td:first", nRow).html(index);
                        return nRow;
                    },
        
                    lengthMenu: [
                        [10, 20, 30, 50, -1],
                        [10, 20, 30, 50, "All"],
                    ],
                    columnDefs: [
                        {
                        targets: 0,
                        render: function (data, type, row, meta) {
                            return type === "export" ? meta.row + 1 : data;
                        },
                        },
                    ],
                    });
                }, 1000);
                });
            }
    }, [])

    const showTable = () => {
            try {
            return props.data.map((item, index) => {
                const {page_name , title , priority} = item; 
                return (
                <tr>
                    <td className="text-xs font-weight-bold">{index +1}</td>
                    <td className="text-xs font-weight-bold">{page_name}</td>
                    <td className="text-xs font-weight-bold">{title}</td>
                    <td className="text-xs font-weight-bold">{priority}</td>
                </tr>
                );
            });
            } catch (e) {
            alert(e.message);
            }
    };

  return(
<>  <div className="container-fluid py-4">
        <div className="table-responsive p-0 pb-2">
            <table id="table" className="table align-items-center justify-content-center mb-0">
                <thead>
                    <tr>
                    <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">S/N</th>
                    <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Page Name</th>
                    <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Title</th>
                    <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Priority</th>
                    </tr>
                </thead>
                <tbody>
                        {showTable()}
                </tbody>
            </table>
        </div>
    </div>
    </>
)

}

export default DataTable;
