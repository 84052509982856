import React, { Suspense } from 'react'
import {BrowserRouter , Navigate} from 'react-router-dom'
import { Routes, Route} from "react-router-loading";
import './admin/scss/style.scss'
import About from '../src/front-end/pages/About'
import CaseStudy from '../src/front-end/pages/CaseStudy'
import CaseStudyProfile from './front-end/pages/CaseStudyProfile'
import News from './front-end/pages/News'
import Contact from './front-end/pages/Contact'
import SiteMap from './front-end/pages/SiteMap'
import ScrollToTop from './front-end/pages/ScrollToTop'
import Services from './front-end/pages/Services'
import ProtectedRoute from './front-end/middleware/Auth';





const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./admin/layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./admin/views/pages/login/Login'))
const Register = React.lazy(() => import('./admin/views/pages/register/Register'))
const Page404 = React.lazy(() => import('./admin/views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./admin/views/pages/page500/Page500'))
const Home = React.lazy(() => import('./front-end/pages/Home'))


const App=()=>{
  

   
    return (
      <BrowserRouter>
        <Suspense fallback={loading}>
        <ScrollToTop>
          <Routes>
            <Route exact path="/" name="Home Page" element={<Home />} loading />
            <Route  path="/about-us" name="About Page" element={<About />} loading />
            <Route exact path="/contact-us" name="About Page" element={<Contact />} loading />
            <Route exact path="/case-study" name="Case Study Page" element={<CaseStudy />} loading />
            <Route exact path="/case-study/analyzing-market-potential-of-patent-foramen-ovale" name="Case Study Deatils Page" element={<CaseStudyProfile />} loading />  
            <Route exact path="/healthcare-press-release" name="News Page" element={<News />} loading />
            <Route exact path="/sitemap" name="Site Map Page" element={<SiteMap />} loading />
            <Route exact path="/service/:slug" name="Site Map Page" element={<Services />} loading />
            <Route exact path="/login" name="Login Page" element={(localStorage.token)? <Navigate replace to="/dashboard"/>: <Login /> } />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <ProtectedRoute>
              <Route path="*" name="Home"  element={(localStorage.token)?<DefaultLayout/>: <Navigate replace to="/login" />} />
              
            </ProtectedRoute>
           
            {/* <Route path="*" name="notfound" element={<NotFound />} /> */}

          </Routes>
          </ScrollToTop>
        </Suspense>
      </BrowserRouter>
    )
  
}

export default App
