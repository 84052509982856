

// import {encode, decode, key} from 'd-encode-decode'
import {encode, decode, key} from './crypt.js'


const default_title="Pharma Consulting Company | Healthcare Consulting"
const default_description="Delveinsight is the key pharma consulting firm and provides pharma market analysis reports. We endue globally the pharma consulting reports worldwide.";
const default_keywords="Pharma Market Analysis, Market Share Pharma, Pharma Market Intelligence, Pharma Companies market share, Pharma Market Growth, Pharm Market, Pharma Market Trends, Pharma Consulting Firms, Best Pharma Consulting Firms, Pharma Consulting, Top pharma consultants, Big pharma consulting, Pharma Consultancy Services, Pharma Consultancy, Pharma Insights Consulting, Pharma Consulting Companies in US, US Pharma, US Pharma Companies, US based pharma companies, biggest pharma companies in US";

export const HONEYPOT = (title=default_title,description=default_description,keywords=default_keywords)=>{
    // let location= useLocation();  
        document.title =title; 
        document.getElementsByTagName("META")[3].content=description;
        document.getElementsByTagName("META")[4].content=keywords;
      
}

export const encode_str=(str)=>{

    key('kdgjfbcnuwe67637jbdfcbx')

    return encode(str)
}

export const decode_str=(str)=>{

    key('kdgjfbcnuwe67637jbdfcbx')

    return decode(str)
}