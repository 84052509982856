const validate =(inputs,setErrors,setLoader) => {
    let nameError = "";
    let emailError = "";
    let phoneError = "";
    if (!inputs.name) {
      nameError = "Name field is required";
    }
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!inputs.email || reg.test(inputs.email) === false) {
      emailError = "Email Field is Invalid ";
    }
    if (!inputs.phone) {
      phoneError = "Phone number field is required";
    }
    if (emailError || nameError || phoneError) {
        setErrors({ nameError, emailError, phoneError });
        setLoader(false)
      return false;
    }
    return true;
}

export default validate;