import React , {useState} from 'react'
import Footer from '../component/footer/Footer'
import NewHeader from '../component/header/NewHeader'
import HeaderImg from '../assets/img/about-us/png/About-us.png'
import {Link} from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ProgressBar from 'react-bootstrap/ProgressBar';
import '../component/styles/styles.js'

import service1 from '../assets/img/about-us/svg/Market-Research-01.png'
import service2 from '../assets/img/about-us/svg/Market-Research-white-01.png'
import service3 from '../assets/img/about-us/svg/Business-Consulting-01.png'
import service4 from '../assets/img/about-us/svg/Business-Consulting-white-01.png'
import service5 from '../assets/img/about-us/svg/Competitive-Intelligence-01.png'
import service6 from '../assets/img/about-us/svg/Competitive-Intelligence-white-01.png'
import service7 from '../assets/img/about-us/svg/Pharmadelve-01.png'
import service8 from '../assets/img/about-us/svg/Pharmadelve-white-01.png'
import Consulting from '../assets/img/about-us/jpg/Next-Generation-Consulting.jpg'
import Bg1 from '../assets/img/about-us/png/form-background-banner.png'
import FormImg from '../assets/img/about-us/jpg/image.jpg'
import AboutImg from '../assets/img/about-us/jpg/Business-Consulting.jpg'
import config from '../../Config'

import Swal from 'sweetalert2'
import axios from 'axios';
import validate from './Validation'

export default function About() {
    let ImgArray = []
    for(let i = 1 ; i <= 24 ; i++ ){
        ImgArray.push('logo-'+i)
    }

    const [inputs, setInputs] = useState({});
    const [errors, setErrors] = useState({})
    const [loader, setLoader] = useState(false)

    const handleChange = (event) => {
        setErrors({});
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoader(true);
        if (validate(inputs, setErrors, setLoader)) {
           var count=((window.location.href).split('/')).length
            var arr=(window.location.href).split('/')
            axios.post(config.api_url+"contact", {
                name: inputs.name,
                email: inputs.email,
                phone: inputs.phone,
                message: inputs.message,
                page_name: arr[count-1]
            }).then(function (response) {
                // handle success
                console.log(response)
                if (response.status == 200) {
                    setInputs('')
                    setErrors('')
                    setLoader(false)
                    return Swal.fire({
                        title: 'success!',
                        text: 'Thank you for showing your interest. We have received your request and our professionals will assist you shortly.',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    })
                } else {
                };
            }).catch(function (error) {
                setLoader(false)
                return Swal.fire({
                    title: 'error!',
                    text: `${error.response.data.reason.phone}`,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            });
        }
    }
  return (
    <>
    <NewHeader />
        {/* Header Section */}
            <section className="page-title-section" style={{
                                    backgroundImage: "url(" + HeaderImg + ")",
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 text-center">
                            <div className="page-title-content">
                                <h1 className="title text-white">About Us</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">About Us</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        {/* Header Section */}

        {/* Services */}
        <section className="serivce-section bg-silver-light pdt-105 pdb-80" data-background="">
            <div className="section-title">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="section-title-left-part mrb-sm-30">
                                <div className="section-left-sub-title mb-20">
                                    <h5 className="sub-title text-primary-color">Services We Offer</h5>
                                </div>
                                <h2 className="title">Our Services</h2>
                            </div>
                        </div>
                        <div className="offset-lg-1 col-lg-6">
                            <div className="section-title-right-part">
                                <p>
                                    We believe in adding value to our customers' businesses by providing them solid business decisions, accurate data analysis and assisting them in accelerating their growth. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-xl-3">
                            <div className="service-box service-box-market-research">
                                <div className="service-icon">
                                    <span className="">
                                        <img src={service1} alt="" style={{width: '30%'}} />
                                    </span>
                                </div>
                                <div className="service-icon2" style={{marginTop: '20px' ,  display: 'none'}}>
                                    <span className=""><img src={service2} alt="" style={{width: '30%'}} /></span>
                                </div>
                                <div className="service-content" style={{paddingTop: '15px'}}>
                                    <div className="title">
                                        <Link to="#"><h3>Market Research</h3></Link>
                                    </div>
                                    <div className="para">
                                        <p>We provide the most up-to-date market analysis and forecast</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3">
                            <div className="service-box service-box-business-consulting">
                                <div className="service-icon">
                                    <span className=""><img src={service3} alt="" style={{width: '30%'}} /></span>
                                </div>
                                <div className="service-icon2" style={{marginTop: '20px' ,  display: 'none'}}>
                                    <span className=""><img src={service4} alt="" style={{width: '30%'}} /></span>
                                </div>
                                <div className="service-content" style={{paddingTop: '15px'}}>
                                    <div className="title">
                                        <Link to="#"><h3>Business Consulting</h3></Link>
                                    </div>
                                    <div className="para">
                                        <p>Our reliable business consulting helps clients in improving growth</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3">
                            <div className="service-box service-box-competitive-intelligence">
                                <div className="service-icon">
                                    <span className=""><img src={service5} alt="" style={{width: '30%'}} /></span>
                                </div>
                                <div className="service-icon2" style={{marginTop: '20px' ,  display: 'none'}}>
                                    <span className=""><img src={service6} alt="" style={{width: '30%'}} /></span>
                                </div>
                                <div className="service-content" style={{paddingTop: '15px'}}>
                                    <div className="title">
                                        <Link to="#"><h3>Competitive Intelligence</h3></Link>
                                    </div>
                                    <div className="para">
                                        <p>Our CI services assists you in gaining a competitive edge</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3">
                            <div className="service-box service-box-pharmdelve">
                                <div className="service-icon">
                                    <span className=""><img src={service7} alt="" style={{width: '30%'}} /></span>
                                </div>
                                <div className="service-icon2" style={{marginTop: '20px' ,  display: 'none'}}>
                                    <span className=""><img src={service8} alt="" style={{width: '30%'}} /></span>
                                </div>
                                <div className="service-content" style={{paddingTop: '15px'}}>
                                    <div className="title">
                                        <Link to="#"><h3>PharmDelve</h3></Link>
                                    </div>
                                    <div className="para">
                                        <p>A subscription-based pharma market research platform</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* Services */}

        {/* consulting */}
        <section className="pdt-0 pdb-210 pdb-md-110 minus-mrt-130 bg-pos-center-bottom" data-background="images/bg/abs-bg1.png">
    `        <div className="section-content">
                <div className="container" style={{paddingTop:'100px'}}>
                    <div className="row">
                        
                    </div>
                    <div className="row align-items-center pdt-80">
                        <div className="col-md-12 col-xl-6">
                            <h3 className="mrb-15 text-primary-color sub-title-side-line" style={{fontSize: '18px'}}>Next Generation Consulting</h3>
                            <h5 className="mrb-30" style={{fontSize:'42px' ,lineHeight:'45px'}}>Our customised consulting services<br /><span className="f-weight-400"> can accelerate your  growth</span> </h5>
                            <p className="mrb-30">DelveInsight is laser-focused on offering real-world answers to our clients' problems. We have a diversified team with extensive experience in the healthcare consulting field.</p>
                            <div className="skills mrb-lg-60">
                                <div className="skill-item">
                                    <div className="skill-header">
                                        <h6 className="skill-title">Business Consulting</h6>
                                        <ProgressBar className="bar progress-line" animated now={85} />
                                        <div className="skill-percentage">
                                            <div className="count-box"><span className="count-text" data-speed="2100" data-stop="85">85</span>%</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="skill-item">
                                    <div className="skill-header">
                                        <h6 className="skill-title">Market Analysis</h6>
                                        <ProgressBar className="bar progress-line" animated now={96} />
                                        <div className="skill-percentage">
                                            <div className="count-box"><span className="count-text" data-speed="2100" data-stop="85">96</span>%</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="skill-item">
                                    <div className="skill-header">
                                        <h6 className="skill-title">Competitive Intelligence</h6>
                                        <ProgressBar className="bar progress-line" animated now={90} />
                                        <div className="skill-percentage">
                                            <div className="count-box"><span className="count-text" data-speed="2100" data-stop="85">90</span>%</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="skill-item">
                                    <div className="skill-header">
                                        <h6 className="skill-title">Business Development</h6>
                                        <ProgressBar className="bar progress-line" animated now={88} />
                                        <div className="skill-percentage">
                                            <div className="count-box"><span className="count-text" data-speed="2100" data-stop="85">88</span>%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-xl-6">
                            <div className="about-image-block">
                                <img className="img-full" src={Consulting} alt="" />
                                <div className="call-us-now">
                                    <p className="call-us-title mrb-5 text-white">Call Us Now</p>
                                    <a href="tel: +19193216187"><h3 className="number mrt-0 text-white">+1(919)321-6187</h3></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>`
        {/* Consulting */}

        {/* Contact Us */}
        <section className="pdb-110" data-overlay-dark="8" style={{backgroundImage:'url('+ Bg1 +')',backgroundSize: 'cover' , backgroundPosition: 'center' , backgroundRepeat: 'no-repeat'}}>
            <div className="section-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="popup-video-block video-popup">
                                <img className="img-full d-none d-lg-block" src={FormImg} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-4 mrb-sm-110">
                            <div className="request-a-call-back-form">
                                <h3 className="mrt-0 mrb-20 solid-bottom-line">Feel Free to Contact Us</h3>
                                <p className="mrb-30">Schedule a Webex meeting with our consultant</p>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <input type="text" name="name" value={inputs.name || ""} onChange={handleChange} placeholder="Name" className="form-control" />
                                                <span className='text-danger'>{errors.nameError}</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <input type="text" value={inputs.phone || ""} onChange={handleChange} placeholder="Phone" name="phone" className="form-control" />
                                                <span className='text-danger'>{errors.phoneError}</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <input type="email" value={inputs.email || ""} onChange={handleChange} placeholder="Email" name="email" className="form-control" />
                                                <span className='text-danger'>{errors.emailError}</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group mrb-25">
                                                <textarea rows="4" value={inputs.message || ""} onChange={handleChange} placeholder="Messages" name="message"
                                                    className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group mrb-0">
                                                <button type="submit" className="cs-btn-one btn-primary-color btn-md btn-block">
                                                {loader == true ? 
                                                <div className="spinner-border" style={{ padding: '10px', marginRight: '20px'}} role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div> : ''}
                                                Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* Contact Us */}

        {/* About Section */}

        <section className="about-section anim-object pdt-110 pdb-30">
            <div className="container">
                <div className="row align-items-center mrb-110 mrb-lg-50 mrb-sm-50">
                    <div className="col-md-12 col-xl-6">
                        <div className="about-image-block-2 mrb-lg-60">
                            <img className="img-full" src={AboutImg} alt="" />
                            <div className="call-us-now">
                                <p className="call-us-title mrb-5 text-white">Call Us Now</p>
                                <a href="tel: +19193216187"><h3 className="number mrt-0 text-white">+1(919)321-6187</h3></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-xl-6">
                        <h4 className="sub-title-side-line text-primary-color mrt-0 mrb-15" style={{fontSize:'18px'}}>Business Consulting</h4>
                        <h6 className="mrb-30" style={{fontSize: '42px' , lineHeight: '45px'}}>We offer best healthcare business consulting solutions</h6>
                        <p className="mrb-40">We have the expertise and knowledge to give the answers you need to move forward, no matter how 
                        difficult your business problems are, and assist you in taking immediate action. We combine powerful analytical 
                        capabilities and human-centered approaches to drive success in your business. We are dedicated to providing value
                        to our clients while also ensuring long-term success. We approach each project from a variety of perspectives and
                        collaborate to create a tailored solution to help you achieve your objectives. Reach out to us if you want to
                        improve your company's performance, innovate and grow, cut costs, leverage talent, and transform it.</p>
                    </div>
                </div>
            </div>
        </section>
          
        {/* About Section */}
        {/* Clients */}
        <section className="pdt-60 pdb-60">
            <div className="section-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <OwlCarousel className="owl-carousel client-items" dots={false} items={4} autoplay={true} autoplayTimeout={3000} loop margin={10}>
                                {
                                    ImgArray.map((img , index) => {
                                        return (
                                        <div className="client-item" key={index}>
                                            <img src={require("../assets/img/home/jpg/logo/"+ img +".jpg")} alt="" />
                                        </div>
                                        )
                                    })
                                }
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* Clients */}

    <Footer />
    </>
  )
}
