import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText
} from 'reactstrap';

import  '../styles/styles.js'

import logo from '../../assets/img/home/png/color.png'

import { LoadingContext } from "react-router-loading";

import axios from 'axios';
import { HONEYPOT } from '../../../helper.js';
import config from '../../../Config.js';

const NewHeader = (props) => {

    // const [state, setState] = useState();
    const [meta, setMeta] = useState({})

    const [isOpen, setIsOpen] = useState(false);

    const text_style = {
        color: '#2f2b2b',
        fontWeight: '600',
        paddingLeft: '10px',
        paddingRight: '25px',
    }
    const toggle = () => setIsOpen(!isOpen);

    const loadingContext = useContext(LoadingContext);

    const loading = async () => {
        //loading some data
        // const data = await loadData();
        // setState(data);
        
        //call method to indicate that loading is done
        loadingContext.done();
    };

    var count=((window.location.href).split('/')).length
    var arr=(window.location.href).split('/')

    useEffect(() => {
        loading();
        axios.get(config.api_url+"meta?slug="+arr[count-1])
        .then((response)=>{
             setMeta(response.data.data)
        }).catch(()=>{

        })
    }, []);

    HONEYPOT(meta?.title,meta?.meta_description,meta?.meta_keyword)

    return (
        <>
     
            <div className="header-style-two">
                <div className="header-top-area bg-gradient-color d-none d-lg-block">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2 header-top-left-part">
                                <span className=""> <a href="tel: +19193216187" style={{ fontWeight: '600', letterSpacing: '0.05em' }}>+1(919)321-6187</a></span>

                            </div>
                            <div className="col-lg-10 header-top-right-part text-right" >
                                <ul className="social-links">
                                    <li><a href="https://www.facebook.com/delveinsightindia/"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="https://twitter.com/delve_insight"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="https://in.linkedin.com/company/delveinsight-business-research-llp"><i className="fab fa-linkedin-in"></i></a></li>
                                </ul>
                                <span className="phone" style={{ display: 'inline-flex', paddingLeft: 'inherit' }}><i className="webexflaticon flaticon-send" style={{ paddingRight: '10px' }}></i><a href="mailto:info@delveinsight.co.uk">info@delveinsight.co.uk</a></span>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Navbar style={{ padding: '15px' }} color="light" fixed="top" sticky="top" light expand="md">
                {/* <NavbarBrand  > */}
                    <Link to="/">
                        <img id="logo-image" className="img-center" src={logo} alt="" style={{ height: '52px', paddingLeft: '20px' }} />
                    </Link>
                {/* </NavbarBrand> */}
                <NavbarText>
                    <NavbarToggler onClick={toggle} />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="mr-auto" navbar>
                            <NavItem>
                                <Link style={text_style}  to="/about-us">About Us</Link>
                            </NavItem>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle style={text_style} nav caret>
                                    Services
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <DropdownItem>
                                        <Link style={text_style} to='/service/market-forecast-analysis'>
                                            Market Forecast Analysis
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Link style={text_style} to='/service/indication-pipeline-analysis'>
                                            Indication Pipeline Analysis
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Link style={text_style} to='/service/competitive-intelligence'>
                                            Competitive Intelligence
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Link style={text_style} to='/service/indication-epidemiological-analysis'>
                                            Indication Epidemiological Analysis
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Link style={text_style} to='/service/rare-disease-analysis'>
                                            Rare Disease Analysis
                                        </Link>
                                    </DropdownItem>

                                    <DropdownItem divider />
                                    <DropdownItem>
                                        <Link style={text_style} to='/service/medical-device-competitive-insights'>
                                            Medical Device Competitive Insights
                                        </Link>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <NavItem>
                                <a style={text_style} href="/blog">Blog</a>
                            </NavItem>
                            <NavItem>
                                <Link style={text_style} to="/case-study">Case Study</Link>
                            </NavItem>
                            <NavItem>
                                <Link style={text_style}  to="/healthcare-press-release">News</Link>
                            </NavItem>
                            <NavItem>
                                <Link style={text_style}  to="/contact-us">Contact Us</Link>
                            </NavItem>
                            <NavItem>
                                <a href="https://www.delveinsight.com/report-store.php" className="cs-btn-one btn-gradient-color" style={{ textAlign: "center", padding: "12px 19px", fontSize: "12px", lineHeight: "20px", width: '100%', color: 'white' }}>Report Store</a>

                            </NavItem>
                        </Nav>
                    </Collapse>
                </NavbarText>
            </Navbar>

        </>
    );
}

export default NewHeader;