import { CRow, CCol } from '@coreui/react'
import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import config from '../../../Config'


import background_image from '../../assets/images/footer-bg.png'

export default function Footer() {


    const [Email, setEmail] = useState('')
   const subscribe =async()=>{
    if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(Email)) { 
        /* return true */ 

       await axios.post(config.api_url+'subscribe',{
        email:Email,
        page_name: window.location.pathname
       }).then((response)=>{
               if(response.data.code===200){
                Swal.fire({
                    icon:'success',
                    title:'Success',
                    text:"You have successfully Subscribe",
                    
                })
                setEmail('');
               }else{
                Swal.fire({
                    icon: 'error',
                    title:'Invalid Email',
                    text:"Please enter valid email!! "
                })
               }
       }).catch((err)=>{
        Swal.fire({
            icon: 'error',
            title:'Something went wrong',
            text: err.response.data.message
        })
       })
        // alert(Email)
    }else{

        Swal.fire({
            icon: 'error',
            title:'Invalid Email',
            text:"Please enter valid email!!"
        })
    }
    }

    return (
        <div>
            <footer className="footer" style={{ padding:0 }}>
                <div className="footer-main-area" style={{
                    backgroundImage: "url(" + background_image + ")",
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    width: '100%'
                }} >
                    <div className="container">
                        <CRow>
                            <CCol xl={4} lg={6} md={6}>
                                <div className="widget footer-widget">
                                    <img src="img/home/png/white.png" alt="" className="mrb-20" />
                                    <address className="mrb-25">
                                        <p className="text-light-gray">304 S. Jones Blvd #2432, Las Vegas NV 89107</p>
                                        <div className="mrb-10"><a href="tel: +19193216187" className="text-light-gray"><i className="fas fa-phone-alt mrr-10"></i>+1(919)321-6187</a></div>
                                        <div className="mrb-10"><a href="mailto:info@delveinsight.co.uk" className="text-light-gray"><i className="fas fa-envelope mrr-10"></i>info@delveinsight.co.uk</a></div>

                                    </address>
                                    <ul className="social-list">
                                        <li><a href="https://www.facebook.com/delveinsightindia/"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="https://twitter.com/delve_insight"><i className="fab fa-twitter"></i></a></li>
                                        <li><a href="https://in.linkedin.com/company/delveinsight-business-research-llp"><i className="fab fa-linkedin"></i></a></li>

                                    </ul>
                                </div>
                            </CCol>
                            <CCol xl={4} lg={6} md={6}>
                                <div className="widget footer-widget">
                                    <h5 className="widget-title text-white mrb-30">Useful Links</h5>
                                    <ul className="footer-widget-list">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/about-us">About</Link></li>
                                        <li><Link to="/case-study">Case Study</Link></li>
                                        <li><Link href="blog/index.html">Blog</Link></li>

                                        <li><Link to="/healthcare-press-release">News</Link></li>
                                        <li><Link to="/contact-us">Contact</Link></li>
                                        <li><Link to="/sitemap">Sitemap</Link></li>
                                        <li><Link to="/login">Login</Link></li>
                                    </ul>
                                </div>
                            </CCol>
                            <CCol xl={4} lg={6} md={6}>
                                <div className="widget footer-widget">
                                    <h5 className="widget-title text-white mrb-30">Newsletter</h5>
                                    <p className="text-light-gray">Subscribe to our newsletter to get the latest news and updates happening in the healthcare industry.</p>

                                    <div>
                                        <input type="email" name="email" onChange={(e)=>{
                                            setEmail(e.target.value)
                                        }} className="form-control" value={Email} placeholder="Enter Your Email" required />
                                    </div>
                                    <button type="submit" className="cs-btn-one btn-gradient-color btn-sm has-icon mrt-20" onClick={()=>{
                                        subscribe();
                                    }}><i className="webexflaticon flaticon-send"></i>Submit Now</button>

                                </div>
                            </CCol>
                        </CRow>
                    </div>
                </div>
                <div className="footer-bottom-area" style={{ width: '100%' }}>
                    <div className="container">
                        <CRow>
                            <CCol xl={12}>
                                <div className="text-center">
                                    <span className="text-light-gray"><Link className="text-primary-color" target="_blank" href="/"> DelveInsight Business Research LLP</Link> © {new Date().getFullYear()} | All rights reserved </span>
                                </div>
                            </CCol>
                        </CRow>
                    </div>
                </div>
            </footer>
        </div>
    )
}
