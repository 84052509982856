
import {  Route,  Navigate} from "react-router-dom";

const ProtectedRoute = ({component:Component, ...rest}) => {
 
return (
  <Route
  {...rest}
  render={(props) =>{
  if(!localStorage.token){
    alert('not')
    return <Navigate to="/login" from="/"/>
  }

  return <Component {...props}/>
  }}
  />
)

};

export default ProtectedRoute;