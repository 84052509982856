import React from 'react'
import Footer from '../component/footer/Footer'
import NewHeader from '../component/header/NewHeader'
import CaseHeaderImg from '../assets/img/case-study/jpg/banner.jpg'
import  '../component/styles/styles.js'
import {Link} from 'react-router-dom'
import ProjectImg from '../assets/img/home/jpg/case-study-final.jpg'

export default function CaseStudy() {
  return (
    <>
    <NewHeader />
    {/* Page Title */}
    <section className="page-title-section" style={{
                                    backgroundImage: "url(" + CaseHeaderImg + ")",
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                }}>
        <div className="container">
            <div className="row">
                <div className="col-xl-12 text-center">
                    <div className="page-title-content">
                        <h3 className="title text-white">Case Study</h3>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Case Study</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* Page Ttitl */}
    {/* Project Section */}
    <section className="bg-silver-light pdt-105 pdb-80" data-background="images/bg/abs-bg4.png">
        <div className="section-content">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-6 col-xl-4">
                        <div className="case-study-item mrb-30">
                            <div className="case-study-thumb">
                                <img className="img-full" src={ProjectImg} alt="" />
                                <div className="case-study-link-icon">
                                    <Link to="/case-study/analyzing-market-potential-of-patent-foramen-ovale"><i className="webex-icon-attachment1"></i></Link>
                                </div>
                                <div className="case-study-details p-4">
                                    <h6 className="case-study-category side-line mrb-5">Case Study</h6>
                                    <h4 className="case-study-title">Analyzing Market Potential of Patent Foramen Ovale</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* Project Section */}
    <Footer />
    </>
  )
}
