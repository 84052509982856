import React ,{useEffect , useState} from 'react'
import 'jquery/dist/jquery.min.js';
import Footer from '../component/footer/Footer'
import NewHeader from '../component/header/NewHeader'
import SiteHeaderImg from '../assets/img/contact-us/jpg/banner.jpg'
import DataTable from './SiteMapData'
import axios from 'axios';
import config from '../../Config';

export default function SiteMap() {

    const [page, setPage] = useState([])

    useEffect(() => {
        axios.get(config.api_url+"sitemap")
          .then((response)=>{
            setPage(response.data)
            console.log(response.data);
          }).catch((error) => {
            console.log(error)
          })
    }, [])

  return (
    <>
    <NewHeader />
        {/* Header */}
        <section className="page-title-section" style={{
                                    backgroundImage: "url(" + SiteHeaderImg + ")",
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                }}>
        <div className="container">
            <div className="row">
                <div className="col-xl-12 text-center">
                    <div className="page-title-content">
                        <h3 className="title text-white">Delveinsight US Sitemap</h3>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="{{ route('index') }}">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Sitemap</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* Header */}
    {/* SiteMap */}
    <section>
        <div className="container">
            <div className="text-center my-4">
                <h3>ALL STATIC PAGES</h3>
            </div>
            <div className="my-3">
                <DataTable data={page}/>
            </div>
        </div>
    </section>
    {/* SiteMap */}
    <Footer />
    </>
  )
}
