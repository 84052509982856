import React , { useState } from 'react'
import Footer from '../component/footer/Footer'
import NewHeader from '../component/header/NewHeader'
import HeaderImg from '../assets/img/case-study/jpg/banner.jpg'
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import axios from 'axios';
import validate from './Validation'
import  '../component/styles/styles.js'
import config from '../../Config.js';

import {Link} from 'react-router-dom'

export default function CaseStudyProfile() {
    
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const handleShow = () => setShow(true);

    const [inputs, setInputs] = useState({});

    const [errors, setErrors] = useState({})

    const [loader, setLoader] = useState(false)

    const handleChange = (event) => {
        setErrors({});
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }

    // validate();

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoader(true);
        if (validate(inputs, setErrors, setLoader)) {
           var count=((window.location.href).split('/')).length
            var arr=(window.location.href).split('/')
            axios.post(config.api_url+"contact", {
                name: inputs.name,
                email: inputs.email,
                phone: inputs.phone,
                company: inputs.company,
                message: inputs.message,
                page_name: arr[count-1]
            }).then(function (response) {
                if (response.status == 200) {
                    setInputs('')
                    setErrors('')
                    setLoader(false)
                    handleClose()
                    return Swal.fire({
                        title: 'success!',
                        text: 'Thank you for showing your interest. We have received your request and our professionals will assist you shortly.',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    })
                } else {
                };
            }).catch(function (error) {
                setLoader(false)
                return Swal.fire({
                    title: 'error!',
                    text: `${error.response.data.reason.phone}`,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            });
        }
    }
  return (
    <>
    <NewHeader />
    {/* Model */}
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{textAlign: 'center'}}>Please fill the details below</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="form-group form-group-new">
                            <input type="text" name="name" value={inputs.name || ""} onChange={handleChange} placeholder="Name" className="form-control form-control-new" />
                            <span className='text-danger'>{errors.nameError}</span>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group mrb-25">
                            <input type="text" value={inputs.phone || ""} onChange={handleChange} placeholder="Phone" name="phone" className="form-control" />
                            <span className='text-danger'>{errors.phoneError}</span>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group form-group-new">
                            <input type="email" name="email" value={inputs.email || ""} onChange={handleChange} placeholder="Email" className="form-control form-control-new" />
                            <span className='text-danger'>{errors.emailError}</span>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group form-group-new">
                            <input type="text" name="company" value={inputs.company || ""} onChange={handleChange} placeholder="Company Name" className="form-control form-control-new" />
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group form-group-new">
                            <input type="textarea" name="message" value={inputs.message || ""} onChange={handleChange} placeholder="Message" className="form-control form-control-new" style={{height: '100px'}}  />
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group mrb-0 text-center">
                            <button type="submit" className="cs-btn-one btn-primary-color btn-sm">{loader == true ? 
                            <div className="spinner-border" style={{ padding: '10px', marginRight: '20px'}} role="status">
                                <span className="sr-only">Loading...</span>
                            </div> : ''} Submit</button>
                        </div>
                    </div>
                </div>
            </form>
            </div>

        </Modal.Body>
    </Modal>
    {/* Model */}
    {/* Header */}
    <section className="page-title-section" style={{
                                    backgroundImage: "url(" + HeaderImg + ")",
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                }}>
        <div className="container">
            <div className="row">
                <div className="col-xl-10 text-center">
                    <div className="page-title-content">
                        <h1 className="title text-white" style={{textAlign:'left'}}>Analyzing Market Potential of Patent Foramen Ovale</h1>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/case-study">Case Study</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Analyzing Market Potential of Patent Foramen Ovale</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* Header */}
    {/* Details */}
    <section className="project-details-page pdt-110 pdb-110">
        <div className="container">
            <div className="row">
                <div className="col-lg-7 col-xl-8">
                    <div className="project-detail-text">
                        <h2 className="project-details-title mrt-0 mrb-15" style={{fontSize: '28px' ,fontWeight:' 700'}}>Problem Statement</h2>
                        <div className="project-details-content">
                            <div className="row mrb-10">
                                <div className="col-lg-12">
                                    <p>A European-based Medical Device Company wanted to understand the entire landscape in terms of current available Patient Pool, Devices Market Size, and Competitor Share for Patent Foramen Ovale (PFO) in the EU to assess the potential value-based market size of Patent Foramen Ovale for aligning business opportunities in this segment, via patient-based epidemiology assessment for market entry opportunities. Furthermore, the client desired reimbursement scenario assessments to make strategic decisions regarding increased access to the PFO device in the EU market.</p>
                                </div>
                            </div>
                            <div className="row mrb-10">
                                <div className="col-lg-12">
                                    <h3 className="mrb-15" style={{fontWeight:' 700'}}>Solution</h3>
                                    <p>We provided the current and addressable market of PFO and the market opportunity to the client. We evaluated the company’s competition to create a compelling landscape. Furthermore, we also provided the market dynamics to assist in the development of better market entry strategies.</p>
                                </div>
                            </div>
                            <div className="row mrb-20">
                                <div className="col-lg-12">
                                    <h4 className="mrb-15" style={{fontSize: '28px' ,fontWeight:' 700'}}>Our Methodology</h4>
                                </div>
                                <div className="col-lg-12 col-xl-12">
                                    <ul className="order-list primary-color">
                                        <li>Understanding the patient pool at risk across indications such as cryptogenic stroke, migraine, decompression sickness, and so on, as well as calculating the treatable pool of patients</li>
                                        <li>Evaluating current treatment practices to incorporate PFO treatment into the regimen.</li>
                                        <li>Deep dive examinations of device prices, treatment processes, and average procedures</li>
                                        <li>Market Size and Forecast Assessment, Pricing, and Reimbursement Reporting</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row mrb-10">
                                <div className="col-lg-12">
                                    <h5 className="mrb-15" style={{fontSize: '28px' ,fontWeight:' 700'}}>Impact</h5>
                                    <p>Based on our data-driven, strategic insights, the client implemented market entry strategies. As per the patient-related outcomes, we provided an understanding of subtle positioning changes. Moreover, we curated a sales plan based on patient pool segmentation analysis.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-xl-4 first-priority">
                    <div className="sidebar-widget">
                        <div className="project-sidebar">
                            <h4 className="mrb-40 widget-title">Project Information</h4>
                            <ul className="list project-info-list">
                                <li style={{fontSize: '15px'}}><span>Industry:</span> MedTech</li>
                                <li style={{fontSize: '15px'}}><span>Geography:</span> <Link to="/">Europe</Link></li>
                                <li style={{fontSize: '15px'}}><span>Client:</span> <Link to="/">Large Medical Device Company</Link></li>
                                <li style={{fontSize: '15px'}}><span>Services:</span> <Link to="/">Market Entry</Link></li>
                                <li style={{fontSize: '15px'}}><span>Client Feedback:</span> <Link to="/">Excellent</Link></li>
                                <li className="mrt-15"><Link className="open-popup cs-btn-one btn-block text-center text-white" variant="primary" onClick={handleShow}>Download Case Study</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* Details */}
    <Footer />
    </>
  )
}
